import React, { FC } from 'react';
import Image from 'gatsby-theme-husky/src/common/Image';
import { ProductSpecificationSectionProps } from 'gatsby-theme-husky/src/components/ProductSpecificationSection/models';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

import './ProductSpecificationSection.scss';

const ProductSpecificationSection: FC<ProductSpecificationSectionProps> = ({
  productSpecificationSection: {
    topText,
    backgroundImageAltText,
    backgroundImage,
    smallTopImage,
    featureList,
    centralImage,
  },
}) => (
  <div className="product-specification-section">
    {centralImage ? (
      <div className="product-specification-section__central-image-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          {/* ! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
          <path d="M504.3 273.6l-112.1 104c-6.992 6.484-17.18 8.218-25.94 4.406c-8.758-3.812-14.42-12.45-14.42-21.1L351.9 288H32C14.33 288 .0002 273.7 .0002 255.1S14.33 224 32 224h319.9l0-72c0-9.547 5.66-18.19 14.42-22c8.754-3.809 18.95-2.075 25.94 4.41l112.1 104C514.6 247.9 514.6 264.1 504.3 273.6z" />
        </svg>
      </div>
    ) : null}
    {backgroundImage ? (
      <Image
        imageData={backgroundImage}
        alt={backgroundImageAltText}
        className="product-specification-section__background-image-wrapper"
      />
    ) : null}
    {topText ? (
      <div className="product-specification-section__top-text-wrapper">
        {topText?.header ? <h2>{topText.header}</h2> : null}
        {topText?.content ? <DangerouslySetInnerHtml html={topText.content} /> : null}
      </div>
    ) : null}

    {smallTopImage ? (
      <div className="product-specification-section__top-image-wrapper">
        <ul>
          {featureList.map(({ content: feature }) => (
            <li key={feature}>
              <p>{feature}</p>
            </li>
          ))}
        </ul>
      </div>
    ) : null}
  </div>
);

export default ProductSpecificationSection;
